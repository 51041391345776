import { render, staticRenderFns } from "./Instructions.vue?vue&type=template&id=22190f97&"
import script from "./Instructions.vue?vue&type=script&lang=js&"
export * from "./Instructions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports